label.MuiFormLabel-root,
label.MuiFormLabel-root.MuiInputLabel-shrink {
    color: #aab4bd;
    &.Mui-focused {
        color: #aab4bd;
    }
    font-weight:normal;
    &.Mui-disabled {
        color: #b8c1c9;
    }
}

label.MuiFormLabel-root {
    transform: translate(12px, 11px) scale(1);
    &.MuiInputLabel-shrink {
        transform: translate(12px, -4px) scale(0.75);
    }
}

fieldset.MuiOutlinedInput-notchedOutline legend {
    display: none;
}