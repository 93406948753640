img#compass-logo {
    height: 55px;
}

#company-phone {
    color: #d28c01;
    font-weight: bold;
    margin-left: 8px;
    &:hover {
        text-decoration: none;
        color: #552505;
    }
}
@media (max-width:500px) {
    img#compass-logo {
        height: 40px;
    }
    #company-phone {
        font-size:14px;
    }
}

h4.page-title {
    font-family: Besley, sans-serif;
}
  

.MuiToolbar-root {  // lines under header
    border-bottom: 2px solid #febd3b;
    padding-bottom: 3px;

    #toolbar-inside-border {
        width: 100%;
        border-bottom: 3px solid #552505;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

@media print {
    .no-print {
        display: none !important;
    }
}
