$tab-colors: (
  fed: #552505,
  feeders: #0a5723,
  calves: #8f5a3b,
  unborn: #d28c01
);

$button-colors: (
  feeders: #d7f3e0,
  calves: #ede5e0,
);

$neutral-gray: #858484;

/*  Hiding menu
 header.MuiAppBar-root {  // app bar
  width: 100%;  
  .header-menu-button {  // 3 bar menu button
    display: none;  
  }
} 

nav.MuiBox-root {  // side menu
  display: none;  
} 
*/

.lrpQuotesPage {
  max-width: 500px;
  margin: 0 auto;

  .page-title {
    text-align: center;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTab-root {
    padding: 10px;
  }

  .phoneNumber {
    font-weight: bold;
  }

  .MRTable {
    margin: 0 auto;
    th.MuiTableCell-head {
      vertical-align: middle;
    }    
  }

  .noActiveQuotes-warning{
      text-align: center;
      background-color: yellow;
      color: red;
      font-weight: bold;
      margin-bottom:5px;
  }

  @each $type, $color in $tab-colors {
    .#{$type}-tab.Mui-selected {
      color: white;
      background-color: $color;
    }

    .#{$type}-tab-quoteType {
      font-weight: bold;
      color: $color;
    }

    .#{$type}-tab-button {
      border: none;
      color: white;
      background-color: $color;
      &:hover {
        background-color: $color;
        opacity: .7;
      }
    }
     
    .#{$type}-tab-quoteType-typeName {
      font-weight: bold;
      font-size: 110%;
      color: $neutral-gray;
      &.selected {
        color: $color;
      }
    }

    .#{$type}-tab-quoteType-weights {
      opacity: 0.6;
      font-size: 90%;
      font-weight: bold;
      color: $neutral-gray;
      &.selected {
        color: $color;
      }
    }
  }

  @each $type, $color in $button-colors {
    .#{$type}-tab-quoteType-button {
      flex-direction: column;
      padding: 0.6rem 0.8rem;
      height: auto;
      border-radius: 5px;
      background: #fff;
      border: 1px solid #f4f4f4;
      &.selected {
        background: $color;
        border: 1px solid $color;
        &:hover {
          box-shadow: none;
          opacity: 1;
        }
      }
      &:hover{
        box-shadow: 0 8px 16px 0 $color;
        background-color: $color;
        filter: grayscale(.7);
      }
    }
  }
}

