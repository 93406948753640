//PADDING
input.MuiInputBase-input,
div.MuiInputBase-input {
    padding: 16px 12px 8px;
    &.MuiInputBase-inputAdornedStart {
        padding-left: 0;
    }
    &.MuiInputBase-inputAdornedEnd {
        padding-right: 0;
    }
}
div.MuiInputBase-root {
    border-radius: 6px;
}

//MARGIN
div.MuiFormControl-root {
    margin-bottom: 12px;
    margin-right: 8px;
}
.control-no-margin {
    .MuiFormControl-root {
        margin: 0;
    }
}
table div.MuiFormControl-root {
    margin: 0;
}

//HELPER TEXT
div.MuiFormHelperText-root {
    margin-top: 2px;
    margin-bottom: 4px;
    font-size: .65rem;
    color: #a9a096;
}

//DISABLED
.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline {
    background: #00000005;
    z-index: 0;    
}

//ADORNMENT
.MuiInputBase-root .MuiInputAdornment-root {
    margin-top: 8px;
}

