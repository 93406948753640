.CasMultiRowTable {
    margin-top: .3rem;
    width: 100%;
    padding: 0 0 0 .4rem;
    
    table td {
        padding: 3px;
        .delete {  //------------- FIXES too high, but can't get class attached yet
            margin-top: -14px;
            top: 14px;
        }
        border: none;
    }

    &.multiRowTableCard {
        table {
            width: 100%;
            tbody tr {
                border: 2px dashed #00000028;
                td td {
                    display: block;
                    border: none;
                }
            }
            tbody tr > td {
                padding: 5px 3px 3px;
            }
        }
    }
}