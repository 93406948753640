.CasTableToolbar {
    padding: 10px 0;
    &.empty {
        padding: 0;
    }
}

@media (max-width:500px) {
    .CasTableToolbar {
        flex-wrap: wrap;
        .MuiStack-root {
            display: contents;
        }
    }
}

.has-value .MuiOutlinedInput-notchedOutline {
    background: rgba(79, 227, 118, 0.08);
    z-index: -1;
}