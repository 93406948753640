.auto-refresh-text {
  text-align: center;
  background: yellow ;
  padding: 3px;
  cursor: pointer;
  font-weight: 500;
}

table.MuiTable-root {
  width: auto;
  min-width: 0;
  .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgb(79 227 118 / 8%);
  }

  tfoot tr td.MuiTableCell-root {
    font-weight: bold;
    color: black;
    background: #f8fafc;
    padding-top: 2px;
  }
}
thead tr.title th {
  background: none;
  text-align: left;
  padding: 2px 0 8px 16px;
  color: #aab4bd;

}
th.MuiTableCell-head {
  color: #637381;
  padding: 8px 12px;
  line-height: 16px;
  vertical-align: bottom;
  .Mui-active {
    color: #637381;
  }
}
td.MuiTableCell-head {
  padding: 12px;
}
td.MuiTableCell-root {
  padding: 8px 12px;
  border-bottom: 1px solid #eeeeee;
}

.MuiTablePagination-input div.MuiInputBase-input {
  padding: 8px 12px 8px;
}

@media (max-width: 500px) {
  .table-list-card {
    thead {
      display: none;
    }
    tr {
      border-bottom: 1px solid black;
      padding: 8px 12px;
      td.MuiTableCell-root {
        display: block;
        padding: 0 0 2px;
        border: none;
      }
    }
  }
}

.table-tbody-nowrap .MuiTableBody-root .MuiTableCell-root {
  text-wrap: nowrap;
}

/* MRTable adjustments */
.MRTable {
  .MuiPaper-root {
    box-shadow: none;
  }

  table.MuiTable-root {
    border-collapse: collapse;
  }

  .Mui-TableHeadCell-Content-Actions {
    display:none;
  }

  &.MRTable-standard {
    th.MuiTableCell-root.MuiTableCell-head,
    td.MuiTableCell-root.MuiTableCell-body {
      width: auto;
      min-width: 0;
    }
  }

  .MRTable-subRow {
    background-color: rgb(225 237 249 / 19%) !important
  }

  th.MuiTableCell-root.MuiTableCell-head {
    padding: 4px;
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
  }
  td.MuiTableCell-root.MuiTableCell-body {
    padding: 4px;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    [aria-label="Expand"] .Mui-disabled,
    [aria-label="Collapse"] .Mui-disabled {  // hide disabled caret expand button
      display: none;
    }
  }

  .MuiTablePagination-root {
    label.MuiFormLabel-root {
      transform: none;
    }
    div.MuiInputBase-input {
      padding: 0 28px 0 0;
    }
  }

  &.MRTable-hide-grouping-header{
    .MuiAlert-message {
      display: none;
    }
  }
}
