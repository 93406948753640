@media (max-width:500px) {
    div.MuiTabs-root {
        padding-left: 0;
        padding-right: 0;
        .MuiTab-root {
            margin-right: 4px;
            padding-left: 6px; 
            padding-right: 6px;
        }        
    }
}
